<template>
  <v-dialog v-model="dialog" max-width="700px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        depressed
        rounded
        color="primary"
        style="float: right"
        class="mt-6 mx-1"
        v-bind="attrs"
        v-on="on"
        >Project Update
      </v-btn>
    </template>
    <v-card>
      <v-form @submit.prevent="saveProjectTask" ref="projectTaskForm">
        <v-row no-gutters class="my-0 py-0">
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-row>
        <v-card-title>
          <v-row class="mx-2">Project Update</v-row>
        </v-card-title>
        <v-card-text>
          <v-alert text color="red" type="error" v-if="errorMsg">
            {{ errorMsg }}
          </v-alert>
          <v-container>
            <v-row>
              <v-col>
                <v-select
                  v-model="newItem.actionType"
                  item-value="attribute"
                  item-text="label"
                  :items="actionTypes"
                  :rules="[inputRequired]"
                  label="Action type"
                  required
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="mb-5">
              <v-col
                v-for="(source, index) in availableSourceTypes"
                :key="index"
              >
                <v-checkbox
                  v-if="
                    (source === 'media' || source === 'telegram' || source === 'facebook') &&
                    newItem.actionType === 'project_parsing'
                  "
                  disabled
                  v-model="newItem.sourceTypes"
                  :label="source"
                  :value="source"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-else-if="!projectItem.sourceTypeList.includes(source)"
                  disabled
                  v-model="newItem.sourceTypes"
                  :label="source"
                  :value="source"
                  hide-details
                ></v-checkbox>
                <v-checkbox
                  v-else
                  v-model="newItem.sourceTypes"
                  :label="source"
                  :value="source"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="5" md="5">
                <vc-date-picker
                  v-model="newItem.start"
                  mode="dateTime"
                  is24hr
                  class="mt-1"
                  :popover="{ placement: $screens({ default: 'left' }) }"
                  :rules="[inputRequired]"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      :rules="[inputRequired]"
                      label="Start Date"
                      :value="inputValue"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
            </v-row>
          </v-container>
          <v-alert v-if="hasErrors" type="error" elevation="0" outlined>
            <div v-for="(error, key) in errors" :key="key">
              {{ prepareErrorMessage(error, key) }}
            </div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey darken-1" text @click="close"> Cancel</v-btn>
          <v-btn color="primary" text @click="saveProjectTask"> Save</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import { EventBus } from "@/utils";
import { NEW_CREATED_TASK } from "@/utils/events";
import { CREATE_TASK } from "@/store/modules/projectTasks/actions";
import { mapGetters } from "vuex";

export default {
  name: "ProjectUpdateTaskAddDialog",
  props: {
    projectId: {
      required: true,
    },
  },
  data() {
    return {
      items: [],
      errors: {},
      dialog: false,
      date: null,
      menuModal: true,
      newItem: {
        actionType: null,
        sourceTypes: [],
        start: null,
      },
      defaultItem: {
        actionType: null,
        sourceTypes: [],
        start: null,
      },
      errorMsg: "",
      inputRequired: (v) => !!v || v === 0 || "Required field",
      actionTypes: [
        {
          label: "Project parsing",
          attribute: "project_parsing",
        },
        {
          label: "Project update",
          attribute: "project_update",
        },
      ],
      availableSourceTypes: [
        "media",
        "facebook",
        "tiktok",
        "twitter",
        "telegram",
        "youtube",
        "linkedin",
      ],
    };
  },
  computed: {
    ...mapGetters("project", ["projectItem"]),
    hasErrors: function () {
      return !_.isEmpty(this.errors);
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    prepareErrorMessage(error, key) {
      return error.replace("{attribute}", key);
    },
    close() {
      this.dialog = false;
      this.errorMsg = null;
      this.errors = {};
      this.$refs.projectTaskForm.resetValidation();
      this.newItem = _.merge({}, this.defaultItem);
    },
    saveProjectTask() {
      let vm = this;
      return this.createProjectTask(function (response) {
        vm.close();
        let { id } = response.data;
        vm.newItem = Object.assign({}, vm.defaultItem);
        EventBus.$emit(NEW_CREATED_TASK, id);
      });
    },
    createProjectTask() {
      let vm = this;
      if (!vm.$refs.projectTaskForm.validate()) {
        this.errorMsg = "Please check all fields!";
      } else {
        let payload = _.merge({ id: this.projectId }, this.newItem);
        if (payload.actionType === "project_parsing") {
          let indexMedia = payload.sourceTypes.indexOf("media");
          if (indexMedia !== -1) {
            payload.sourceTypes.splice(indexMedia, 1);
          }
          let indexTelegram = payload.sourceTypes.indexOf("telegram");
          if (indexTelegram !== -1) {
            payload.sourceTypes.splice(indexTelegram, 1);
          }
          let indexFacebook = payload.sourceTypes.indexOf("facebook");
          if (indexFacebook !== -1) {
            payload.sourceTypes.splice(indexFacebook, 1);
          }
        }
        if (payload.sourceTypes.length < 1) {
          this.errorMsg = "Please choose source types!";
          return;
        }
        payload.sourceTypes = payload.sourceTypes.join(",");

        this.$store
          .dispatch(`projectTasks/${CREATE_TASK}`, payload)
          .then(
            (response) => {
              this.dataLoading = false;
              if (response.result) {
                // this.accountForm = defaultValues;
                // this.$emit("submitted", response.data);
                vm.errors = {};
                vm.close();
                let { id } = response.data;
                vm.newItem = Object.assign({}, vm.defaultItem);
                EventBus.$emit(NEW_CREATED_TASK, id);
              } else {
                vm.errors = response.details;
              }
            },
            (err) => {
              console.log("Err", err);
            }
          )
          .catch(() => {});
      }
    },
    getCurrentDate() {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = String(currentDate.getMonth() + 1).padStart(2, "0");
      let day = String(currentDate.getDate()).padStart(2, "0");
      let hours = String(currentDate.getHours()).padStart(2, "0");
      let minutes = String(currentDate.getMinutes()).padStart(2, "0");

      return `${year}/${month}/${day} ${hours}:${minutes}`;
    },
  },
};
</script>
